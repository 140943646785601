.lobby-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.placeholder-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url("./assets/background_game.png");
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.username-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 40px;
  width: 540px;
  /* Glass/Modal */
  background: rgba(26, 29, 33, 0.96);
  /* Glass/Modal */

  box-shadow: 0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    inset 16px 24px 64px -24px rgba(255, 255, 255, 0.04),
    inset 0px 8px 12px rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  box-sizing: border-box;
}

.modal-title {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}

.game-code {
  background: linear-gradient(45deg, #3045c9 0%, #65beda 45.31%, #9ad37f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.modal-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.input-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #9b9c9e;
}

.input-field {
  align-self: stretch;
  background: #1a1d21;
  height: 48px;

  /* Outline/Heisenberg Blue */
  box-shadow: 0px 0px 0px 4px rgba(132, 220, 245, 0.24);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 16px;
  color: #ffffff;
  border: none;
}

.modal-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 48px;
}
.modal-join-game-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 12px;
  width: 100%;
  height: 48px;
  /* Heisenberg Blue/500 */
  background: #82dbf7;
  border-radius: 12px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  align-items: center;
  letter-spacing: 0.15px;

  /* Day Blue/900 */
  color: #0c1132;
}

.modal-error-message {
  color: #d0302f;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0px;
}
