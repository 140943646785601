::-webkit-scrollbar {
  background-color: transparent;
}

.game-main-container {
  position: flex;
  width: 100vw;
  height: 100vh;
  background: #202d38;
  background-size: 100px;
  background-image: url(./assets/background_texture.png);
  overflow: hidden;
}

.game-side-bar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 0px;
  position: absolute;
  width: 280px;
  height: 100%;
  left: 24px;
  background: rgba(15, 16, 18, 0.7);
  box-shadow: 0px 12px 24px -16px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
}

.game-side-bar-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 24px;
  gap: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  box-sizing: border-box;
}

.game-side-bar-code-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */
  margin: 0;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, #3045c9 0%, #65beda 45.31%, #9ad37f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.game-side-bar-scoreboard-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 8px;
  gap: 24px;
  width: 100%;
  height: auto;
  /* Glass/Stroke */
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.game-side-bar-scoreboard-header {
  display: flex;
  margin: 0;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #cdcecf;
}

.game-side-bar-scoreboard-players-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.game-side-bar-scoreboard-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 16px;
  width: 100%;
  /* height: 48px; */
  border-radius: 8px;
  box-sizing: border-box;
}

.game-side-bar-scoreboard-player-icon {
  background-image: url("./assets/icons/solid_square.svg");
  width: 20px;
  height: 20px;
}

.game-side-bar-scoreboard-player-name {
  color: #e8e9e9;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.game-side-bar-scoreboard-player-score {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  /* Glass/Fill */
  background: linear-gradient(
    117.58deg,
    rgba(215, 237, 237, 0.16) -47.79%,
    rgba(204, 235, 235, 0) 100%
  );
  /* Glass/Stroke */
  border: 1px solid rgba(255, 255, 255, 0.08);
  /* Shadow/Small */
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05));
  border-radius: 4px;
  margin-left: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.game-side-bar-bid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px;
  gap: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #e8e9e9;
}

.negative-score {
  color: #ff4d4d;
}

.positive-score {
  color: #9ad37f;
}

.zero-score {
  color: #e8e9e9;
}

.game-log {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 12px 24px;
  isolation: isolate;
  gap: 8px;
  position: absolute;
  /* default width is 320px */
  width: 300px;
  height: 640px;
  right: 24px;
  top: 24px;
  z-index: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  word-wrap: anywhere;
  background-color: rgba(15, 16, 18, 0.7);
  border-radius: 20px 20px 0px 0px;
  backdrop-filter: blur(6px);

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 156% */
  letter-spacing: 0.15px;

  /* Noble Black/200 */
  color: #cdcecf;
}

.game-input-log {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 12px 24px;
  isolation: isolate;

  position: absolute;
  width: 300px;
  height: 48px;
  right: 24px;
  top: 664px;
  z-index: 1;

  /* background-color: rgba(166, 176, 242, 0.2); */
  /* background-color: rgba(150, 150, 150, 0.7); */
  /* background-color: rgba(173, 216, 230, 0.7); */
  /* background-color: rgba(152, 251, 152, 0.7); */
  background-color: #387993;
  backdrop-filter: blur(6px);
  border-radius: 0px 0px 20px 20px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.game-input-log::placeholder {
  color: rgba(150, 150, 150, 1);
}

.game-top-notification {
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  position: absolute;
  width: fit-content;
  height: 48px;
  left: 50%;
  transform: translate(-50%);
  top: 20px;

  /* Noble Black/800 */

  background: #0d0f10;
  /* opacity: 0.7; */
  border-radius: 8px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.game-field {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 600px;
  height: 360px;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
      120.61deg,
      rgba(255, 255, 255, 0) 1.57%,
      rgba(255, 255, 255, 0.135) 46.91%,
      rgba(255, 255, 255, 0.12) 65.31%,
      rgba(255, 255, 255, 0) 102.7%
    ),
    #00597b;
  /* Glass/Stroke */
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  /* Shadow Large */

  box-shadow: 0px 25px 50px -12px rgba(6, 7, 8, 0.16),
    0px 8px 10px -6px rgba(6, 7, 8, 0.06);
  border-radius: 20px;
}

.game-field-bidding {
  z-index: 1;
  box-sizing: border-box;
  position: absolute;
  width: 600px;
  height: 360px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);

  background: rgba(26, 29, 33, 0.96);
  /* Glass/Stroke */

  border-top: 1px solid rgba(255, 255, 255, 0.08);
  /* Glass/Modal */

  box-shadow: 0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    inset 16px 24px 64px -24px rgba(255, 255, 255, 0.04),
    inset 0px 8px 12px rgba(255, 255, 255, 0.04);
  border-radius: 20px;
}

.game-field-text {
  position: absolute;
  left: 25px;
  top: 21px;
  margin: 0;
  /* 18/Medium */

  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  /* Noble Black/200 */
  color: #cdcecf;
}

.game-field-combination-text {
  position: absolute;
  right: 25px;
  bottom: 21px;
  margin: 0;
  /* 18/Medium */

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 156% */
  align-items: center;
  letter-spacing: 0.15px;

  /* Noble Black/200 */
  color: #e8e9e9;
}

.game-field-center {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 189px;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  justify-content: center;
  color: #cdcecf;
}

.game-field-cards {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
}

.game-player {
  position: relative;
  min-width: 80px;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 16px 24px;
  gap: 4px;
  backdrop-filter: blur(12px);
  background: radial-gradient(
      96.88% 108.19% at 63.56% 9.82%,
      rgba(196, 225, 225, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      117.58deg,
      rgba(215, 237, 237, 0.16) -47.79%,
      rgba(204, 235, 235, 0) 100%
    ),
    rgba(166, 176, 242, 0.2);

  border-radius: 16px;
}

.game-player-is-playing {
  /* Glass/Modal */
  box-shadow: 0px 24px 64px -16px rgba(0, 0, 0, 0.24),
    inset 16px 24px 64px -24px rgba(255, 255, 255, 0.04),
    inset 0px 8px 12px rgba(255, 255, 255, 0.04);
}

.game-player-active-icon {
  background-image: url("./assets/icons/solid_triangle.svg");
  width: 14px;
  height: 14px;
  position: absolute;
  left: -6px;
  top: 20px;
}

.game-player-passed-icon {
  background-image: url("./assets/icons/solid_cross.svg");
  width: 14px;
  height: 14px;
  z-index: 1;
  color: red;
  position: absolute;
  left: -6px;
  top: 20px;
}

.game-player-landlord-icon {
  /* Frame 3 */
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 24px;

  background: linear-gradient(
      117.58deg,
      rgba(215, 237, 237, 0.16) -47.79%,
      rgba(204, 235, 235, 0) 100%
    ),
    #391c08;
  border: 1.5px solid #e2b42b;
  box-shadow: 0px 0px 12px rgba(226, 180, 43, 0.48);
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #e2b42b;

  letter-spacing: 0.15px;
}

.game-player-you {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 384px;
}

.game-player-right {
  position: absolute;
  left: 624px;
  top: 50%;
  transform: translate(0%, -50%);
}

.game-player-top {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 384px;
}

.game-player-left {
  position: absolute;
  right: 624px;
  top: 50%;
  transform: translate(0%, -50%);
}

.game-card-hand {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  height: 144px;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%);
}

.collapse-button-1 {
  position: absolute;
  left: -30px;
  bottom: 16px;
  color: #9b9c9e;
}

.collapse-button-2 {
  position: absolute;
  left: -30px;
  bottom: 25px;
  color: #9b9c9e;
}

.collapse-button-1-four-player {
  position: absolute;
  left: -30px;
  bottom: 29px;
  color: #9b9c9e;
}

.collapse-button-2-four-player {
  position: absolute;
  left: -30px;
  bottom: 38px;
  color: #9b9c9e;
}

.game-card {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -30px;
  height: 109px;
  width: 80px;
  border-radius: 8px;
}

.game-card-hidden {
  visibility: hidden;
}

.game-card-small {
  width: 60px;
  height: 81.75px;
}

.game-card:hover {
  fill: rgba(230, 230, 230, 0.4);
}

.card-selected {
  transform: translate(0px, -30px);
}

.game-player-name {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
  gap: 8px;
}

.game-player-cards-left {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.game-actions-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  gap: 8px;
  z-index: 0;
  position: absolute;
  height: 164px;
  right: 0px;
  bottom: 0px;
}

.primary-button:disabled {
  background-color: rgba(205, 206, 207, 0.1);
  color: #686b6e;
  border: none;
}

.primary-button {
  box-sizing: border-box;
  background-color: #00597b;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.15px;
  padding: 8px 24px;
  gap: 12px;
  width: auto;
  height: 48px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ebedfc;
  border: none;
}

.primary-button:hover:enabled {
  background: #a1e4f9;
}

.primary-button:active:enabled {
  background: #c0edfb;
}

.secondary-button {
  box-sizing: border-box;
  background-color: transparent;
  border: 3px solid #00597b;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.15px;
  padding: 8px 24px;
  gap: 12px;
  width: auto;
  height: 48px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ebedfc;
}

.secondary-button:disabled {
  background: rgba(205, 206, 207, 0.1);
  color: #686b6e;
  border: none;
}
