.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 160px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 960px;
  box-sizing: border-box;
}

.title {
  display: flex;
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
}

.title-header {
  display: flex;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  font-style: normal;
  line-height: 44px;
  margin-bottom: 24px;
}

.room-code-input {
  background-color: #1a1d21;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 12px;
  width: 424px;
  height: 48px;
  border: 1px solid #363a3d;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.join-button {
  background-color: #82dbf7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.15px;
  padding: 8px 24px;
  gap: 12px;
  width: 192px;
  height: 48px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.join-button:hover {
  background: #a1e4f9;
}
.join-button:active {
  background: #c0edfb;
}

.generate-lobby-container {
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 640px;
  height: 48px;
}

.divider {
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  color: #686b6e;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

hr {
  width: 297.5px;
  height: 0px;
  border: 1px solid #363a3d;
  transform: rotate(-180deg);
}

.create-button {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 8px 24px;
  gap: 12px;
  height: 48px;
  background: linear-gradient(
    117.58deg,
    rgba(215, 237, 237, 0.16) -47.79%,
    rgba(204, 235, 235, 0) 100%
  );
  box-shadow: 0px 24px 24px -16px rgba(0, 0, 0, 0.12),
    inset 16px 24px 64px -24px rgba(255, 255, 255, 0.08),
    inset 0px 8px 12px rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9b9c9e;
  outline: none;
  border: none;
}

.create-button:hover {
  color: #cdcecf;
}

.footer {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 640px;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #686b6e;
}

.footer-link {
  background: linear-gradient(45deg, #82dbf7 0%, #b6f09c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.magic-text {
  background: linear-gradient(45deg, #3045c9 0%, #65beda 45.31%, #9ad37f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

body,
button,
input,
select,
textarea {
  font-family: "Plus Jakarta Sans", sans-serif;
}

body {
  background-color: #131619;
}
